<template>
    <section class="tt-center">
        <Nav
            class="mt-5 mb-4"
            route-name="StrategicProjects"
            parent-page-name="Стратегические проекты"
            current-page-name="Инновационный индустриальный центр ПАО Газпром нефть – Университет ИТМО"
        />

        <div class="tt-center__main-img gpn-center d-none d-xl-flex">
            <div class="tt-center__blurred-img gpn-center mt-lg-auto ms-auto d-flex">
                <h2 class="tt-center__title mt-lg-auto">
                    Инновационный индустриальный центр ПАО Газпром нефть – Университет ИТМО
                </h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="d-block ms-auto mt-auto mb-3 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="col-12 mt-5">
            <div class="row d-flex mx-1">
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <img class="w-100" src="../../assets/gpn-center-about.png" alt="">
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-3">
                    <h3 class="tt-center-goal__title text-center text-lg-start mx-3">
                        О проекте
                    </h3>

                    <p class="tt-center-goal__text mx-3">
                        Целью центра является эффективная реализация проектов полного инновационного цикла, соответствующих мировому уровню актуальности и значимости в приоритетных областях технологического развития Российской Федерации и направленных на достижение технологического суверенитета нефтегазового сектора, а также подготовка и интеграция в отрасль сильнейших инженерных кадров и кадров для цифровой трансформации.
                    </p>
                </div>
            </div>
        </div>

        <div class="col-12 mt-5">
            <div class="row mx-1">
                <div class="col-12 col-md-6">
                    <h3 class="tt-center-goal__title text-center text-lg-start">
                        Партнёрство
                    </h3>

                    <p class="tt-center-goal__text mt-3">
                        Университет ИТМО и ПАО «Газпром нефть» совместно создали инновационный индустриальный центр мирового уровня, который займется разработкой сенсоров, роботов, систем управления беспилотниками, вычислительных систем и других цифровых решений для нефтегазовой отрасли.
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex justify-content-center align-items-end h-100">
                        <img src="../../assets/gpn.svg" alt="" width="240" height="110">
                    </div>                    
                </div>
            </div>
        </div>

        <div class="col-12 mt-5">
            <h3 class="tt-center-goal__title text-center text-lg-start mx-3">
                Ключевые направления деятельности центра:
            </h3>
            <div class="row mt-3 mx-1">
                <div class="col-12 col-md-9">
                    <div class="d-flex flex-wrap col-12" style="gap: 1rem">
                        <div class="key-card col-12 col-lg-4"><p>IIoT / Сенсорика / Носимые устройства</p></div>
                        <div class="key-card col-12 col-lg-4"><p>Беспилотные воздушные судна</p></div>
                        <div class="key-card col-12 col-lg-4"><p>Искусственный интеллект / «Видеоаналитика»</p></div>
                        <div class="key-card col-12 col-lg-4"><p>Роботизация</p></div>
                        <div class="key-card col-12 col-lg-4"><p>Квантовые вычисления</p></div>
                        <div class="key-card col-12 col-lg-4"><p>ПО для промышленных систем</p></div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="notice-card h-100 px-4 py-3">
                        Центр обеспечит полный цикл инноваций: от научных открытий до создания готовых технологий, продуктов и их дистрибуции на рынке. На старте проекта ежегодный объем заказов оценивается в 300 млн рублей.
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { onMounted } from "vue";
import routerMixin from "@/mixins/routerMixin";
import Nav from "@/components/Nav/Nav";

export default {
    name: "InnovativeCenterGpn",

    components: {
        Nav
    },

    setup() {
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })
    }
}
</script>

<style>
.tt-center__main-img.gpn-center {
    background-image: url('../../assets/gpn-center.png')
}

.tt-center__blurred-img.gpn-center {
    background-image: url('../../assets/gpn-center-blur.png')
}

.tt-center__main-img.gpn-center .tt-center__title {
    font-size: 36px;
}

.key-card {
    background: rgba(242, 242, 242, 0.3);
    height: 180px;
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #2A2822;
}

.key-card p {
    max-width: 75%;
}

@media (min-width: 992px)  {
    .key-card.col-lg-4 {
        width: calc(100% / 3 - 1rem)
    }    
}

.notice-card {
    background: rgba(242, 242, 242, 0.3);
    border-radius: 10px;
    color: #2A2822;
    font-size: 18px;
}
</style>